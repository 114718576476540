<template>
  <v-app>
    <ModalOwner
        :form.sync="owner_form"
        :visible.sync="modal_owner"
        :address-type.sync="ownerAddressType"
        @click:submit="addOwnerAddress()"
        @click:return="[changeState('ROUTE_CALCULATION'), modal_owner = false]"
        @click:close-dialog="[changeState('ROUTE_CALCULATION'), modal_owner = false]"
    />
    <ModalTermsAndConditions
        :visible.sync="modal_agreement"
        :accepted.sync="termsAccepted"
        :loading="loadingEmail"
        @send="sendEmail()"
      />

    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="orange darken-1 elevation-0" dark>
              <v-btn
                  style="pointer-events: none"
                  text
                  x-large
                  class="font-weight-medium"
              >
                お見積もり
              </v-btn>
              <v-btn
                  style="pointer-events: none"
                  text
                  x-large
                  class="font-weight-medium"
              >
                {{ headerText }}
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-alert type="error" outlined>
                <div class="font-weight-bold">
                  {{ alertMessage }}
                </div>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary" class="mr-4" @click="addPickUpAddress()">
            <v-icon left>mdi-plus</v-icon>
            集荷先追加
          </v-btn>
          <v-btn color="primary" @click="addDeliveryAddress()">
            <v-icon left>mdi-plus</v-icon>
            配達先追加
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <v-form ref="addressesForm">
            <v-row>
              <v-col cols="12" v-for="address in addresses" :key="`address-${address.id}`" :id="`address-${address.id}`">
                <div class="headline font-weight-bold ml-3">
                  {{ address.type === 'pickup' ? '集荷先' : '配達先' }}

                </div>
                <v-row>
                  <v-col cols="12">
                    <FormPickup
                        v-if="address.type === 'pickup'"
                        :form="pick_up_addresses.find(item => item.id === address.id)"
                        :first-item="checkIfFirst('pickup', address.id)"
                    />
                    <FormDelivery
                        v-if="address.type === 'delivery'"
                        :form="delivery_addresses.find(item => item.id === address.id)"
                        :first-item="checkIfFirst('delivery', address.id)"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn color="green" @click="calculate()" v-if="addresses.length > 1" class="white--text mt-4">適用</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <div id="map" style="width: 100%; height: 0px;margin-top: 20px;"></div>
            </v-col>
            <v-col cols="12" v-if="showToll">
              <v-row align="center">
                <v-col cols="auto">
                  <div class="heading font-weight-bold ml-3">
                    高速利用
                  </div>
                </v-col>
                <v-col cols="auto">
                  <v-radio-group row v-model="toll" @change="calculate()">
                    <v-radio :value="true" label="可"></v-radio>
                    <v-radio :value="false" label="不可"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-col>
          </v-row>

          <template v-if="showResult">
            <v-row>
              <v-col cols="auto">
                <v-row>
                  <v-col cols="auto">
                    走行距離
                  </v-col>
                  <v-col cols="auto">
                    {{ totalDistance }} km
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto">
                <v-row>
                  <v-col cols="auto">
                    お見積金額
                  </v-col>
                  <v-col cols="auto">
                    {{ priceWithTax.toLocaleString('jp-JP') }}円 (税込)
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto">
                <v-row>
                  <v-col cols="auto">
                    ＊高速料金は含まず
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">発注後のキャンセルは１台あたり5,000円となります。<br>
                お見積金額は軽貸物車両１台あたりの運賃となります。<br>
                配達が日付を跨いだ場合は積み置き料金として１日あたり5,000円が必要となります。
              </v-col>
            </v-row>

            <v-row v-if="state !== 'FINALIZING'" justify="center">
              <v-col cols="12" class="text-center">
                <v-btn
                    color="green darken-1"
                    class="white--text mx-4"
                    @click="changeState('DELIVERY_ENTRY')"
                    min-width="130"
                >
                  戻る
                </v-btn>
                <v-btn
                    color="green darken-1"
                    class="white--text mx-4"
                    @click="changeState('OWNER_ENTRY')"
                    min-width="130"
                >
                  お申し込みに進む
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col cols="12">
        </v-col>
      </v-row>

      <v-row class="mt-8" v-if="state === 'FINALIZING'">
        <div class="headline font-weight-bold ml-3">
          ご依頼者（ご依頼者）情報を入力
        </div>
        <v-row>
          <v-col cols="12">
            <FormOwner read-only :address-type="ownerAddressType" :form="owner_form" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto">
            <v-row>
              <v-checkbox v-model="termsAccepted">
                <template v-slot:label>
                  <div @click="showAgreementModal()">
                    標準貨物軽自動車運送約款に合意し発注
                  </div>
                </template>
              </v-checkbox>
            </v-row>
            <v-btn
                color="green darken-1"
                class="white--text mx-4"
                @click="changeState('OWNER_ENTRY')"
                min-width="130"
            >
              戻る
            </v-btn>
            <v-btn
                color="green darken-1"
                class="white--text mx-4"
                @click="sendEmail()"
                min-width="130"
                :disabled="!termsAccepted"
            >
              発注
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {v4} from "uuid";
import {clone, isEmpty, last} from "lodash";
// eslint-disable-next-line no-unused-vars
import PriceCalculator from "./price_calculator";
import ModalOwner from "@/components/ModalOwner";
// import ModalPickup from "@/components/ModalPickup";
// import ModalDelivery from "@/components/ModalDelivery";
import dayjs from "@/plugins/dayjs";
import ModalTermsAndConditions from "@/components/ModalTermsAndConditions";
import axios from "axios";
import FormPickup from "@/components/FormPickup";
import FormDelivery from "@/components/FormDelivery";
import FormOwner from "@/components/FormOwner";

export default {
  name: "App",
  components: {FormOwner, FormDelivery, FormPickup, ModalTermsAndConditions, ModalOwner},
  data() {
    return {
      map: null,
      distanceMatrixService: null,
      directionsService: null,
      directionsRenderer: null,
      showResult: false,
      priceWithTax: 0,
      priceWithoutTax: 0,
      totalDistance: 0,
      extraAddress: 0,
      isReady: false,
      modal_pick_up: true,
      modal_owner: false,
      modal_delivery: false,
      modal_agreement: false,

      pick_up_addresses: [],
      delivery_addresses: [],
      pick_up_address_form: {
        id: null,
        company_name: null,
        address: null,
        address_detail: '',
        person: null,
        phone: null,
        email: null,
        payment_method: null,
        high_speed_use: null,
        content_of_luggage: null,
        weight_of_luggage: null,
        driver_message: null,
        datetime: dayjs().format('YYYY-MM-DD HH:mm'),
      },

      delivery_address_form: {
        id: null,
        company_name: null,
        person: null,
        phone: null,
        email: null,
        address: null,
        address_detail: '',
        datetime: dayjs().format('YYYY-MM-DD HH:mm'),
      },

      // Owner Form Vue Object
      owner_form: {
        id: null,
        person: null,
        company_name: null,
        phone: null,
        email: null,
        address: '',
        address_detail: '',
        content_of_luggage: null
      },
      originAddress: '',
      destinationAddress: '',
      checkpoints: [],
      termsAccepted: false,
      loadingEmail: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      state: "PICKUP_ENTRY",
      lastPickupId: '',
      showToll: false,
      toll: false,
      ownerAddressType: '',
      pickupCopy: false,
      deliveryCopy: false,
      order: 0
    };
  },

  computed: {
    alertMessage() {
      if(this.state === 'PICKUP_ENTRY')
        return "集荷先情報を入力してください。"

      if(this.state === 'DELIVERY_ENTRY')
        return "配達先住所を入力してください。"

      if(this.state === 'ROUTE_CALCULATION')
        return "有料道路の通行を許可しますか？"

      if(this.state === 'OWNER_ENTRY')
        return "ご依頼者（お支払い方）情報を入力してください"

      if(this.state === 'FINALIZING')
        return "入力情報に間違いがないかご確認ください"

      return ""
    },
    headerText() {
      if(this.state === 'PICKUP_ENTRY' || this.state === 'DELIVERY_ENTRY')
        return "お申し込み"
      return "発注"
    },
    lastPickupAddress() {
      return last(this.pick_up_addresses) || null
    },
    lastDeliveryAddress() {
      return last(this.delivery_addresses) || null
    },
    addresses() {
      let arr = []
      arr.push(...this.pick_up_addresses.map(item => { return {...item, type: 'pickup' }}))
      arr.push(...this.delivery_addresses.map(item => { return {...item, type: 'delivery' }}))
      arr.sort((a, b) => a.order - b.order)
      return arr
    }
  },

  mounted() {
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 35.652778, lng: 139.839444 },
      zoom: 10,
    });
    this.distanceMatrixService = new window.google.maps.DistanceMatrixService();
    this.directionsService = new window.google.maps.DirectionsService();

    this.addPickUpAddress();
    this.addDeliveryAddress();
  },

  watch: {
    state: function(newValue, oldValue) {
        if(newValue === 'DELIVERY_ENTRY' && oldValue === 'PICKUP_ENTRY'){
          this.modal_pick_up = false
          this.modal_delivery = true
          if(this.pick_up_address_form.address) {
            this.addPickUpAddress()
          }
          this.resetDeliveryForm()
        } else if(newValue === 'PICKUP_ENTRY' && oldValue === 'DELIVERY_ENTRY') {
          this.modal_delivery = false
          this.modal_pick_up = true
          this.resetPickupForm()
          // this.editLastPickupAddress()
        } else if(newValue === 'ROUTE_CALCULATION' && oldValue === 'DELIVERY_ENTRY') {
          this.modal_delivery = false
          if(this.delivery_address_form.address) {
            this.addDeliveryAddress()
          }
          this.calculate()
        } else if(newValue === 'DELIVERY_ENTRY' && oldValue === 'ROUTE_CALCULATION') {
          this.modal_delivery = true
          // this.editLastDeliveryAddress()
        } else if(newValue === 'OWNER_ENTRY') {
          this.modal_owner = true
        }
      },
    ownerAddressType: function(newValue) {
      if(newValue === 'pickup') {
        this.owner_form.company_name = this.lastPickupAddress.company_name
        this.owner_form.person = this.lastPickupAddress.person
        this.owner_form.phone = this.lastPickupAddress.phone
        this.owner_form.email = this.lastPickupAddress.email
        this.owner_form.address = this.lastPickupAddress.address
        this.owner_form.address_detail = this.lastPickupAddress.address_detail
      } else if(newValue === 'delivery') {
        this.owner_form.company_name = this.lastDeliveryAddress.company_name
        this.owner_form.person = this.lastDeliveryAddress.person
        this.owner_form.phone = this.lastDeliveryAddress.phone
        this.owner_form.email = this.lastDeliveryAddress.email
        this.owner_form.address = this.lastDeliveryAddress.address
        this.owner_form.address_detail = this.lastDeliveryAddress.address_detail
      }
      this.owner_form.content_of_luggage = this.lastPickupAddress.content_of_luggage
    },
    pickupCopy: function(newValue) {
      this.copyPickupDetail(newValue)
    },
    deliveryCopy: function(newValue) {
      this.copyDeliveryAddressToPickup(newValue)
    }
  },
  methods: {
    openModalPickUp() {
      this.modal_pick_up = true;
      this.resetPickupForm()
      this.changeState('PICKUP_ENTRY')
    },

    resetPickupForm() {
      this.pick_up_address_form = {
        id: null,
        company_name: null,
        person: null,
        phone: null,
        content_of_luggage: null,
        weight_of_luggage: null,
        address: null,
        address_detail: '',
        datetime: dayjs().format('YYYY-MM-DD HH:mm'),
      }
      this.pickupCopy = false
    },

    addPickUpAddress() {
      let item = this.pick_up_address_form;
      item.id ||= v4();
      item.order = this.order
      this.order++
      this.pick_up_addresses.push(clone(item));
      this.resetPickupForm()
      this.lastPickupId = item.id

      this.$nextTick(() => {
        const el = document.getElementById(`address-${item.id}`)
        el?.scrollIntoView({behavior: 'smooth'})
      })
    },

    editLastPickupAddress() {
      this.pick_up_address_form = clone(last(this.pick_up_addresses))
      this.pick_up_addresses.pop()
    },

    openModalDelivery() {
      this.modal_delivery = true;
      this.resetDeliveryForm()
    },

    resetDeliveryForm() {
      this.delivery_address_form = {
        id: null,
        company_name: null,
        person: null,
        phone: null,
        email: null,
        address: null,
        address_detail: '',
        datetime: dayjs().format('YYYY-MM-DD HH:mm'),
      };
      this.deliveryCopy = false
    },

    addDeliveryAddress() {
      let item = this.delivery_address_form;
      item.id ||= v4();
      item.order = this.order
      this.order++
      this.delivery_addresses.push(clone(item))
      this.resetDeliveryForm()

      this.$nextTick(() => {
        const el = document.getElementById(`address-${item.id}`)
        el?.scrollIntoView({behavior: 'smooth'})
      })
    },

    editLastDeliveryAddress() {
      this.delivery_address_form = clone(last(this.delivery_addresses))
      this.delivery_addresses.pop()
    },

    returnToEditPickup() {
      if(this.lastPickupAddress?.delivery_addresses.length) {
        return this.editLastDeliveryAddress()
      }
      this.changeState('PICKUP_ENTRY')
    },

    changeState(state) {
      this.state = state
    },

    copyPickupDetail(args) {
      if(args) {
        this.pick_up_address_form.company_name = this.lastPickupAddress.company_name;
        this.pick_up_address_form.person = this.lastPickupAddress.person;
        this.pick_up_address_form.phone = this.lastPickupAddress.phone;
        this.pick_up_address_form.address = this.lastPickupAddress.address;
        this.pick_up_address_form.address_detail = this.lastPickupAddress.address_detail;
      } else {
        this.resetPickupForm()
      }
    },

    copyDeliveryAddressToPickup(args) {
      if(args) {
        this.delivery_address_form.company_name = this.lastPickupAddress.company_name;
        this.delivery_address_form.person = this.lastPickupAddress.person;
        this.delivery_address_form.phone = this.lastPickupAddress.phone;
        this.delivery_address_form.address = this.lastPickupAddress.address;
        this.delivery_address_form.address_detail = this.lastPickupAddress.address_detail;
      } else {
        this.resetDeliveryForm()
      }
    },

    addOwnerAddress() {
      let item = this.owner_form;
      item.id ||= v4();
      this.owner_form = item
      this.modal_owner = false;
      this.state = 'FINALIZING'
    },
    async calculate() {


      if(this.$refs.addressesForm.validate()) {
        this.$refs.addressesForm.resetValidation()
      } else {
        return
      }

      this.showToll = true
      document.getElementById("map").style.height = "500px";

      // if(this.pick_up_addresses.length === 1 && this.delivery_addresses.length === 1) {
      //   let pickupAddress = this.pick_up_addresses[0].address
      //   let deliveryAddress = this.delivery_addresses[0].address
      //
      //   if(!pickupAddress || !deliveryAddress) {
      //
      //   }
      // }

      this.showResult = false;
      this.extraAddress = 0;
      this.totalDistance = 0;
      this.originAddress = '';
      this.destinationAddress = '';
      this.checkpoints = [];

      let deliveryAddresses = []
      deliveryAddresses.push(...this.pick_up_addresses.filter(item => item.address))
      deliveryAddresses.push(...this.delivery_addresses.filter(item => item.address))
      deliveryAddresses.sort((a, b) => a.order - b.order)
      deliveryAddresses = deliveryAddresses.map(address => {
        return `${address.address} ${address.address_detail}`
      })
      this.originAddress = deliveryAddresses[0]
      deliveryAddresses.shift()
      this.checkpoints.push(...deliveryAddresses)
      this.destinationAddress = last(this.checkpoints);
      this.checkpoints.pop();
      this.extraAddress = (this.pick_up_addresses.length + this.delivery_addresses.length) - 2;
      await this.drawRoute();
      this.showResult = true;
    },
    async drawRoute() {
      let destinations = []
      if(!isEmpty(this.checkpoints)) {
        destinations.push(...this.checkpoints)
      }
      destinations.push(this.destinationAddress)
      let distanceMatrixResponse = await this.distanceMatrixService.getDistanceMatrix(
        {
          origins: [`${this.originAddress}`],
          destinations: destinations,
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: !this.toll,
          avoidTolls: !this.toll,
        }
      );
      if(this.directionsRenderer) {
        this.directionsRenderer.set('directions', null)
      }
      let status = distanceMatrixResponse.rows[0]?.elements[0]?.status
      if(status === 'NOT_FOUND' || status === 'ZERO_RESULTS') {
        this.snackbar = true
        this.snackbarText = "入力された住所が間違っております。確認してください。"
        this.snackbarColor = 'error'
        return
      }
      let addresses = [];
      for (const addressIndex in distanceMatrixResponse.destinationAddresses) {
        addresses.push({
          address: distanceMatrixResponse.destinationAddresses[addressIndex],
          duration:
            distanceMatrixResponse.rows[0].elements[addressIndex].duration
              .value,
        });
      }
      let orderedAddresses = addresses;
      let destination = orderedAddresses[orderedAddresses.length - 1];
      let waypoints = [];
      for (const deliveryAddress of orderedAddresses) {
        if (deliveryAddress.address !== destination.address) {
          waypoints.push({ location: deliveryAddress.address, stopover: true });
        }
      }
      let directionServiceResponse = await this.directionsService.route({
        origin: `${this.originAddress}`,
        destination: destination.address,
        waypoints: waypoints,
        optimizeWaypoints: false,
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: !this.toll,
        avoidTolls: !this.toll,
      });
      if(this.directionsRenderer) {
        this.directionsRenderer.set('directions', null)
      }
      this.directionsRenderer = new window.google.maps.DirectionsRenderer();
      this.directionsRenderer.setDirections(directionServiceResponse);
      this.directionsRenderer.setMap(this.map);
      let totalDistance = 0;
      for (const leg of directionServiceResponse.routes[0].legs) {
        totalDistance += leg.distance.value;
      }
      if (totalDistance > 0) {
        totalDistance = totalDistance / 1000;
      }
      this.totalDistance += Math.round(totalDistance);

      await this.calculatePrice()
    },
    async calculatePrice() {
      let price = PriceCalculator(this.totalDistance);
      this.priceWithoutTax = parseInt(price.withoutTax) + this.extraAddress * 1500;
      this.priceWithTax = parseInt(price.withTax) + this.extraAddress * 1500;
      // this.priceWithoutTax = price
      // this.priceWithTax = this.priceWithoutTax * 1.1
      // if(this.extraAddress > 0) {
      //   this.priceWithTax += this.extraAddress * 1500
      // }
    },
    showAgreementModal() {
      this.modal_agreement = true
    },
    sendEmail() {
      if(!this.termsAccepted) return

      this.loadingEmail = true

      let addresses = [
        ...this.pick_up_addresses.map(pickup => { return {...pickup, type: 'pickup' }}),
        ...this.delivery_addresses.map(delivery => { return {...delivery, type: 'delivery' }})
      ].map(item => {
        item.address = `${item.address} ${item.address_detail}`
        return item
      })
      addresses.sort((a, b) => a.order - b.order)

      let data = {
        distance: this.totalDistance,
        cost: this.priceWithTax,
        owner: this.owner_form,
        addresses: addresses
      }

      axios.post('backend/mailer.php', data)
          .then(() => {
            this.modal_agreement = false
            this.snackbarColor = 'success'
            this.snackbarText = 'ご依頼内容が送信されました。'
          })
          .catch(error => {
            console.error(error);
            this.snackbarColor = 'error'
            this.snackbarText = 'メール送信に失敗しました。'
          })
          .finally(() => {
            this.snackbar = true
            this.loadingEmail = false
          })

    },
    checkIfFirst(type, id) {
      let arr = type === 'pickup' ? this.pick_up_addresses : this.delivery_addresses
      let index = arr.findIndex(address => address.id === id)
      return index === 0
    }
  },
};
</script>
