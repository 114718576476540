<template>
  <v-dialog v-model="_visible" max-width="600px">
    <v-card>
      <v-card-text>
          <div class="cls_002"><span class="cls_002">○ 標準貨物軽自動車運送約款 ( 平成十五年国土交通省告示第百七十一号 )</span></div>
          <div class="cls_002"><span class="cls_002">最終改正 平成三十一年 国土交通省告示第三百二十一号</span></div>
          <div class="cls_002"><span class="cls_002">目次</span></div>
          <div class="cls_002"><span class="cls_002">第一章 総則 ( 第一条・第二条 )</span></div>
          <div class="cls_002"><span class="cls_002">第二章 運送業務等 ( 第三条 - 第五十八条 )</span></div>
          <div class="cls_002"><span class="cls_002">第一節 通則 ( 第三条 - 第五条 )</span></div>
          <div class="cls_002"><span class="cls_002">第二節 引受け ( 第六条 - 第十五条 )</span></div>
          <div class="cls_002"><span class="cls_002">第三節 積付け、積込み又は取卸し ( 第十六条 )</span></div>
          <div class="cls_002"><span class="cls_002">第四節 貨物の受取及び引渡し ( 第十七条 - 第二十四条 ) 第五節 指図 ( 第二十五条・第二十六条 )</span></div>
          <div class="cls_002"><span class="cls_002">第六節 事故 ( 第二十七条 - 第二十九条 )</span></div>
          <div class="cls_002"><span class="cls_002">第七節 運賃及び料金 ( 第三十条 - 第三十七条 )</span></div>
          <div class="cls_002"><span class="cls_002">第八節 責任 ( 第三十八条 - 第五十条 )</span></div>
          <div class="cls_002"><span class="cls_002">第九節 連絡運輸 ( 第五十一条 - 第五十八条 )</span></div>
          <div class="cls_002"><span class="cls_002">第三章 附帯業務 ( 第五十九条 - 第六十一条 ) 第一章 総則</span></div>
          <div class="cls_002"><span class="cls_002">( 事業の種類 )</span></div>
          <div class="cls_002"><span class="cls_002">第一条 当店は、貨物軽自動車運送事業を行います。2 当店は、前項の事業に附帯する事業を行います。</span></div>
          <div class="cls_002"><span class="cls_002">( 適用範囲 )</span></div>
          <div class="cls_002"><span class="cls_002">第二条 当店の経営する貨物軽自動車運送事業に関する運送契約は、この運送約款の定めるところにより、この運送約款に定めのない事項については、法令又は一般の慣習によります。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項の規定にかかわらず、法令に反しない範囲で、特約の申込み に応じることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">第二章 運送業務等</span></div>
          <div class="cls_002"><span class="cls_002">第一節 通則</span></div>
          <div class="cls_002"><span class="cls_002">( 受付日時 )</span></div>
          <div class="cls_002"><span class="cls_002">第三条 当店は、受付日時を定め、店頭に掲示します。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の受付日時を変更する場合には、あらかじめ店頭に掲示します。</span></div>
          <div class="cls_002"><span class="cls_002">( 運送の順序 )</span></div>
          <div class="cls_002"><span class="cls_002">第四条 当店は、運送の申込みを受けた順序により貨物の運送を行います。た だし、腐敗又は変質しやすい貨物を運送する場合その他正当な事由がある場 合は、この限りでありません。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡期間 )</span></div>
          <div class="cls_002"><span class="cls_002">第五条 当店の貨物の引渡期間は、次の日数を合算した期間とします。</span></div>
          <div class="cls_002"><span class="cls_002">一 発送期間 貨物を受け取った日を含め二日</span></div>
          <div class="cls_002"><span class="cls_002">二 輸送期間 運賃及び料金の計算の基礎となる輸送距離百七十キロメートルにつき一日。ただし、一日未満の端数は一日とします。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定による引渡期間の満了後、貨物の引渡しがあったときは、これをもって延着とします。</span></div>
          <div class="cls_002"><span class="cls_002">第二節 引受け</span></div>
          <div class="cls_002"><span class="cls_002">( 貨物の種類及び性質の確認 )</span></div>
          <div class="cls_002"><span class="cls_002">第六条 当店は、貨物の運送の申込みがあったときは、その貨物の種類及び性質を通知することを申込者に求めることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項の場合において、貨物の種類及び性質につき申込者が通知したことに疑いがあるときは、申込者の同意を得て、その立会いの上で、これを点検することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、前項の規定により点検をした場合において、貨物の種類及び性質 が申込者の通知したところと異ならないときは、これにより生じた損害の賠償をします。</span></div>
          <div class="cls_002"><span class="cls_002">4 当店が、第二項の規定により点検をした場合において、貨物の種類及び性質が申込者の通知したところと異なるときは、申込者に点検に要した費用を負担していただきます。</span></div>
          <div class="cls_002"><span class="cls_002">( 引受拒絶 )</span></div>
          <div class="cls_002"><span class="cls_002">第七条 当店は、次の各号の一に該当する場合には、運送の引受けを拒絶することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">一 当該運送の申込みが、この運送約款によらないものであるとき。</span></div>
          <div class="cls_002"><span class="cls_002">二 申込者が、前条第一項の規定による通知をせず、又は同条第二項の規定による点検の同意を与えないとき。</span></div>
          <div class="cls_002"><span class="cls_002">三 当該運送に適する設備がないとき。</span></div>
          <div class="cls_002"><span class="cls_002">四 当該運送に関し、申込者から特別の負担を求められたとき。</span></div>
          <div class="cls_002"><span class="cls_002">五 当該運送が、法令の規定又は公の秩序若しくは善良の風俗に反するものであるとき。</span></div>
          <div class="cls_002"><span class="cls_002">六 天災その他やむを得ない事由があるとき。</span></div>
          <div class="cls_002"><span class="cls_002">( 送り状等 )</span></div>
          <div class="cls_002"><span class="cls_002">第八条 荷送人は、次の事項を記載した送り状を、一口ごとに交付しなければなりません。ただし、個人 ( 事業として又は事業のために運送契約の当事者 となる場合におけるものを除く。</span></div>
          <div class="cls_002"><span class="cls_002">第三十条第二項において同じ。) が荷送人 2 である場合であって、当店がその必要がないと認めたときは、この限りでは ありません。</span></div>
          <div class="cls_002"><span class="cls_002">一 貨物の品名、品質及び重量又は容積並びにその荷造りの種類及び個数</span></div>
          <div class="cls_002"><span class="cls_002">二 発送地及び到達地 ( 団地、アパートその他高層建築物にあっては、その名称及び電話番号を含む。)</span></div>
          <div class="cls_002"><span class="cls_002">三 運送の扱種別</span></div>
          <div class="cls_002"><span class="cls_002">四 運賃、料金 ( 第三十二条に規定する積込料及び取卸料、第三十三条に規定する待機時間料、第五十九条第一項に規定する附帯業務料等をい う。)、</span></div>
          <div class="cls_002"><span class="cls_002">燃料サーチャージ、有料道路利用料、立替金その他の費用 ( 以下 「運賃、料金等」という。) の額その他その支払に関する事項</span></div>
          <div class="cls_002"><span class="cls_002">五 荷送人及び荷受人の氏名又は商号並びに住所及び電話番号</span></div>
          <div class="cls_002"><span class="cls_002">六 高価品については、貨物の種類及び価額</span></div>
          <div class="cls_002"><span class="cls_002">七 貨物の積込み又は取卸しを委託するときは、その旨</span></div>
          <div class="cls_002"><span class="cls_002">八 第五十九条第一項に規定する附帯業務を委託するときは、その旨 九 運送保険に付することを委託するときは、その旨</span></div>
          <div class="cls_002"><span class="cls_002">十 その他その貨物の運送に関し必要な事項</span></div>
          <div class="cls_002"><span class="cls_002">2 荷送人は、送り状の交付に代えて、運送人の承諾を得て、送り状に記載す べき事項を電磁的方法により提供することができます。この場合においては、荷送人は、送り状を交付したものとみなします。</span></div>
          <div class="cls_002"><span class="cls_002">3 荷送人は、当店が第一項の送り状の交付の必要がないと認めたときは、当 店に第一項各号に掲げる事項を通知しなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">( 高価品及び貴重品 )</span></div>
          <div class="cls_002"><span class="cls_002">第九条 この運送約款において高価品とは、次に掲げるものをいいます。</span></div>
          <div class="cls_002"><span class="cls_002">一 貨幣、紙幣、銀行券、印紙、郵便切手及び公債証書、株券、債券、商品 券その他の有価証券並びに金、銀、白金</span></div>
          <div class="cls_002"><span class="cls_002">その他の貴金属、イリジウム、タングステンその他の稀金属、金剛石、紅玉、緑柱石、琥珀、真珠その他の 宝玉石、象牙、べっ甲、珊瑚及び各その製品</span></div>
          <div class="cls_002"><span class="cls_002">二 美術品及び骨董品</span></div>
          <div class="cls_002"><span class="cls_002">三 容器及び荷造りを加え一キログラム当たりの価格が二万円を超える貨物 ( 動物を除く。)</span></div>
          <div class="cls_002"><span class="cls_002">2 前項三号の一キログラム当たりの価格の計算は、一荷造りごとに、これをします。</span></div>
          <div class="cls_002"><span class="cls_002">3 この運送約款において貴重品とは、第一項第一号及び第二号に掲げるもの をいいます。</span></div>
          <div class="cls_002"><span class="cls_002">( 運送の扱種別等不明な場合 )</span></div>
          <div class="cls_002"><span class="cls_002">第十条 当店は、荷送人が運送の申込みをするに当たり、運送の扱種別その他その貨物の運送に関し必要な事項を明示しなかった場合は、荷送人にとって 最も有利と認められるところにより、当該貨物の運送をします。</span></div>
          <div class="cls_002"><span class="cls_002">3 ( 荷造り )</span></div>
          <div class="cls_002"><span class="cls_002">第十一条 荷送人は、貨物の性質、重量、容積、運送距離及び運送の扱種別等 に応じて、運送に適するように荷造りをしなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、貨物の荷造りが十分でないときは、必要な荷造りを要求します。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、荷造りが十分でない貨物であっても、他の貨物に対し損害を与えないと認め、かつ、荷送人が書面により荷造りの不備による損害を負担する ことを承諾したときは、その運送を引き受けることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 外装表示 )</span></div>
          <div class="cls_002"><span class="cls_002">第十二条 荷送人は、貨物の外装に次の事項を見やすいように表示しなければなりません。ただし、当店が必要がないと認めた事項については、この限り でありません。</span></div>
          <div class="cls_002"><span class="cls_002">一 荷送人及び荷受人の氏名又は商号及び住所</span></div>
          <div class="cls_002"><span class="cls_002">二 品名</span></div>
          <div class="cls_002"><span class="cls_002">三 個数</span></div>
          <div class="cls_002"><span class="cls_002">四 その他運送の取扱いに必要な事項</span></div>
          <div class="cls_002"><span class="cls_002">2 荷送人は、当店が認めたときは、前項各号に掲げる事項を記載した荷札を</span></div>
          <div class="cls_002"><span class="cls_002">もって前項の外装表示に代えることができます。</span></div>
          <div class="cls_002"><span class="cls_002">( 動物等の運送 )</span></div>
          <div class="cls_002"><span class="cls_002">第十三条 当店は、動物その他特殊な管理を要する貨物の運送を引き受けたときは、荷送人又は荷受人に対して次に掲げることを請求することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">一 当店において、持込み又は受取の日時を指定すること。</span></div>
          <div class="cls_002"><span class="cls_002">二 当該貨物の運送につき、付添人を付すること。</span></div>
          <div class="cls_002"><span class="cls_002">( 危険品についての特則 )</span></div>
          <div class="cls_002"><span class="cls_002">第十四条 荷送人は、爆発、発火その他運送上の危険を生ずるおそれのある貨物については、その旨を当該貨物の外部の見やすい箇所に明記するととも に、</span></div>
          <div class="cls_002"><span class="cls_002">あらかじめ、その旨及び当該貨物の品名、性質その他の当該貨物の安全 な運送に必要な情報を当店に通知しなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">( 連絡運輸又は利用運送 )</span></div>
          <div class="cls_002"><span class="cls_002">第十五条 当店は、荷送人の利益を害しない限り、引き受けた貨物の運送を他の運送機関と連絡して、又は他の貨物自動車運送事業者の行う運送若しくは 他の運送機関を利用して運送することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">第三節 積付け、積込み又は取卸し</span></div>
          <div class="cls_002"><span class="cls_002">( 積付け、積込み又は取卸し )</span></div>
          <div class="cls_002"><span class="cls_002">第十六条 貨物の積付けは、当店の責任においてこれを行います。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、貨物の積込み又は取卸しを引き受けた場合には、当店の責任にお いてこれを行います。</span></div>
          <div class="cls_002"><span class="cls_002">3 シート、ロープ、建木、台木、充てん物その他の積付用品は、通常貨物自動車運送事業者が備えているものを除き、荷送人又は荷受人の負担とします。</span></div>
          <div class="cls_002"><span class="cls_002">第四節 貨物の受取及び引渡し</span></div>
          <div class="cls_002"><span class="cls_002">( 受取及び引渡しの場所 )</span></div>
          <div class="cls_002"><span class="cls_002">第十七条 当店は、送り状に記載され、又は通知された発送地において荷送人又は荷送人の指定する者から貨物を受取り、送り状に記載され、又は通知さ れた到達地において荷受人又は荷受人の指定する者に貨物を引渡します。</span></div>
          <div class="cls_002"><span class="cls_002">( 管理者等に対する引渡し )</span></div>
          <div class="cls_002"><span class="cls_002">第十八条 当店は、次の各号に掲げる場合には、当該各号に掲げる者に対する貨物の引渡しをもって荷受人に対する引渡しとみなします。</span></div>
          <div class="cls_002"><span class="cls_002">一 荷受人が引渡先に不在の場合には、その引渡先における同居者、従業員又はこれに準ずる者</span></div>
          <div class="cls_002"><span class="cls_002">二 船舶、寄宿舎、旅館等が引渡先の場合には、その管理者又はこれに準ずる者</span></div>
          <div class="cls_002"><span class="cls_002">( 留置権の行使 )</span></div>
          <div class="cls_002"><span class="cls_002">第十九条 当店は、貨物に関し受け取るべき運賃、料金等又は品代金等の支払を受けなければ、当該貨物の引渡しをしません。</span></div>
          <div class="cls_002"><span class="cls_002">2 商人である荷送人が、その営業のために当店と締結した運送契約について、運賃、料金等を所定期日までに支払わなかったときは、</span></div>
          <div class="cls_002"><span class="cls_002">当店は、その支払を受けなければ、当該荷送人との運送契約によって当店が占有する荷送人所有の貨物の引渡しをしないことがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 指図の催告 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十条 当店は、荷受人を確知することができない場合は、遅滞なく、荷送人に対し、相当の期間を定め貨物の処分につき指図すべきことを催告することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、荷受人が、貨物の受取を拒み、又はその他の理由によりこれを受け取ることができない場合には、遅滞なく、荷受人に対し、相当の期間を定 め、その貨物の受取を催告し、</span></div>
          <div class="cls_002"><span class="cls_002">その期間経過の後、さらに、荷送人に対し、前項に規定する指図と同じ内容の催告をすることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡不能の貨物の寄託 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十一条 当店は、荷受人を確知することができない場合又は前条第二項の場合には、荷受人の費用をもって、その貨物を倉庫営業者に寄託することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項の規定により貨物の寄託をしたときは、遅滞なく、その旨を荷送人又は荷受人に対して通知します。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、第一項の規定により貨物の寄託をした場合において、倉荷証券を作らせたときは、その証券の交付をもって貨物の引渡しに代えることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">4 当店は、第一項の規定により寄託をした貨物の引渡しの請求があった場合において、当該貨物について倉荷証券を作らせたときは、</span></div>
          <div class="cls_002"><span class="cls_002">運賃、料金等及び 寄託に要した費用の弁済を受けるまで、当該倉荷証券を留置することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡不能の貨物の供託 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十二条 当店は、荷受人を確知することができない場合又は第二十条第二 項の場合には、その貨物を供託することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項の規定により貨物の供託をしたときは、遅滞なく、その旨を荷送人又は荷受人に対して通知します。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡不能の貨物の競売 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十三条 当店は、第二十条の規定により荷送人に対して指図すべきことを求めた場合において、荷送人が指図をしないときは、その貨物を競売することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定にかかわらず、損傷その他の事由による価格の低落のおそれがある貨物は、第二十条の催告をしないで競売することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、前二項の規定により貨物の競売をしたときは、遅滞なく、その旨を荷送人又は荷受人に対して通知します。</span></div>
          <div class="cls_002"><span class="cls_002">4 当店は、第一項又は第二項の規定により貨物の競売をしたときは、その代 価の全部又は一部を運賃、料金等並びに指図の請求及び競売に要した費用に 充当し、</span></div>
          <div class="cls_002"><span class="cls_002">不足があるときは、荷送人にその支払を請求し、余剰があるとき は、これを荷送人に交付し、又は供託します。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡不能の貨物の任意売却 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十四条 当店は、荷受人を確知することができない場合又は第二十条第二 項の場合において、その貨物が腐敗又は変質しやすいものであって、</span></div>
          <div class="cls_002"><span class="cls_002">第二十 条の手続をとるいとまがないときは、その手続によらず、公正な第三者を立 ち会わせて、これを売却することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定による売却には、前条第三項及び第四項の規定を準用します。</span></div>
          <div class="cls_002"><span class="cls_002">第五節 指図</span></div>
          <div class="cls_002"><span class="cls_002">( 貨物の処分権 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十五条 荷送人は、当店に対し、貨物の運送の中止、返送、転送その他の処分につき指図をすることができます。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項に規定する荷送人の権利は、貨物が到達地に到着した場合において、荷受人が貨物の引渡し又はその損害賠償の請求をしたときは、行使することができません。</span></div>
          <div class="cls_002"><span class="cls_002">3 第一項の指図をする場合において、当店が要求したときは、指図書を提出しなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">( 指図に応じない場合 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十六条 当店は、運送上の支障が生ずるおそれがあると認める場合には、前条第一項の規定による指図に応じないことがあります。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定により、指図に応じないときは、遅滞なく、その旨を荷送人に通知します。</span></div>
          <div class="cls_002"><span class="cls_002">第六節 事故</span></div>
          <div class="cls_002"><span class="cls_002">( 事故の際の措置 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十七条 当店は、次の場合には、遅滞なく、荷送人に対し、相当の期間を定め、その貨物の処分につき指図を求めます。</span></div>
          <div class="cls_002"><span class="cls_002">一 貨物の著しい滅失、損傷その他の損害を発見したとき。</span></div>
          <div class="cls_002"><span class="cls_002">二 当初の運送経路又は運送方法によることができなくなったとき。</span></div>
          <div class="cls_002"><span class="cls_002">三 相当の期間、当該運送を中断せざるを得ないとき。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項各号の場合において、指図を待ついとまがないとき又は当店 の定めた期間内に前項の指図がないときは、荷送人の利益のために、当店の 裁量によって、</span></div>
          <div class="cls_002"><span class="cls_002">当該貨物の運送の中止若しくは返送又は運送経路若しくは運 送方法の変更その他の適切な処分をすることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">3 第一項の規定による指図には、前条の規定を準用します。</span></div>
          <div class="cls_002"><span class="cls_002">( 危険品等の処分 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十八条 当店は、第十四条の規定による通知及び明記をしなかった爆発、発火その他運送上の危険を生ずるおそれのある貨物について、必要に応じ、いつでもその取卸し、</span></div>
          <div class="cls_002"><span class="cls_002">破棄その他運送上の危険を除去するための処分をする ことができます。同条の規定による通知及び明記をした場合において、当該 貨物が他に損害を及ぼすおそれが生じたときも同様とします。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項前段の処分に要した費用は、すべて荷送人の負担とします。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、第一項の規定による処分をしたときは、遅滞なくその旨を荷送人に通知します。</span></div>
          <div class="cls_002"><span class="cls_002">( 事故証明書の発行 )</span></div>
          <div class="cls_002"><span class="cls_002">第二十九条 当店は、貨物の全部滅失に関し証明の請求があったときは、その貨物の引渡期間の満了の日から一月以内に限り、事故証明書を発行します。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、貨物の一部滅失、損傷又は延着に関し、その数量、状態又は引渡しの日時につき証明の請求があったときは、当該貨物の引渡しの日に限り、事故証明書を発行します。</span></div>
          <div class="cls_002"><span class="cls_002">ただし、特別な事情がある場合は、当該貨物の引渡しの日以降においても、発行することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">第七節 運賃及び料金</span></div>
          <div class="cls_002"><span class="cls_002">( 運賃及び料金 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十条 運賃及び料金並びにその適用方法は、当店が別に定める運賃料金表によります。</span></div>
          <div class="cls_002"><span class="cls_002">2 個人を対象とした運賃及び料金並びにその適用方法は、営業所その他の事業所の店頭に掲示します。</span></div>
          <div class="cls_002"><span class="cls_002">( 運賃、料金等の収受方法 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十一条 当店は、貨物を受け取るときまでに、荷送人から運賃、料金等を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の場合において、運賃、料金等の額が確定しないときは、その概算額の前渡しを受け、運賃、料金等の確定後荷送人に対し、その過不足を払い戻し、又は追徴します。</span></div>
          <div class="cls_002"><span class="cls_002">3 当店は、第一項の規定にかかわらず、貨物を引き渡すときまでに、運賃、料金等を荷受人から収受することを認めることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 積込料又は取卸料 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十二条 当店は、貨物の積込み又は取卸しを引き受けた場合には、当店が別に定める料金又は実際に要した費用を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">( 待機時間料 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十三条 当店は、車両が貨物の発地又は着地に到着後、荷送人又は荷受人の責により待機した時間 ( 荷送人又は荷受人が貨物の積込み若しくは取卸し</span></div>
          <div class="cls_002"><span class="cls_002">又は第五十九条第一項に規定する附帯業務を行う場合における待機した時間 を含む。) に応じて、当店が別に定める料金を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">( 延滞料 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十四条 当店は、貨物を引き渡したときまでに、荷送人又は荷受人が運賃、料金等を支払わなかったときは、貨物を引き渡した日の翌日から運賃、料金等の支払を受けた日までの期間に対し、</span></div>
          <div class="cls_002"><span class="cls_002">年利十四 . 五パーセントの割合 で、延滞料の支払いを請求することがあります。</span></div>
          <div class="cls_002"><span class="cls_002">( 運賃請求権 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十五条 当店は、貨物の全部又は一部が天災その他やむを得ない事由により滅失し、若しくは相当程度の損傷を生じたとき又は当店が責任を負う事由 により滅失したときは、</span></div>
          <div class="cls_002"><span class="cls_002">当該滅失し、又は損傷を生じた貨物に係る運賃、料 金等を請求しません。この場合において、当店は既に運賃、料金等の全部又 は一部を収受しているときは、これを払い戻します。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、貨物の全部又は一部がその性質若しくは欠陥又は荷送人の責任に よる事由によって滅失したときは、当該滅失し、又は損傷を生じた貨物に係 る運賃、料金等の全額を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">( 事故等と運賃、料金 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十六条 当店は、第二十五条及び第二十七条の規定により処分をしたとき は、その処分に応じて、又は既に行った運送の割合に応じて、運賃、料金等 を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">ただし、既にその貨物について運賃、料金等の全部又は一部 を収受している場合には、不足があるときには、荷送人又は荷受人にその支 払を請求し、余剰があるときは、これを荷送人又は荷受人に払い戻します。</span></div>
          <div class="cls_002"><span class="cls_002">( 中止手数料 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十七条 当店は、運送の中止の指図に応じた場合には、荷送人が責任を負</span></div>
          <div class="cls_002"><span class="cls_002">わない事由によるときを除いて、中止手数料を請求することがあります。た だし、荷送人が、貨物の積込みの行われるべきであった日の前日までに運送 の中止をしたときは、この限りではありません。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の中止手数料は、次の各号のとおりとします。</span></div>
          <div class="cls_002"><span class="cls_002">一 積合せ貨物の運送にあっては、一運送契約につき五百円 二 貸切り貨物の運送にあっては、一両につき二千五百円</span></div>
          <div class="cls_002"><span class="cls_002">第八節 責任</span></div>
          <div class="cls_002"><span class="cls_002">( 責任の始期 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十八条 当店の貨物の滅失、損傷についての責任は、貨物を荷送人から受 け取った時に始まります。</span></div>
          <div class="cls_002"><span class="cls_002">( 責任と挙証 )</span></div>
          <div class="cls_002"><span class="cls_002">第三十九条 当店は、貨物の受取から引渡しまでの間にその貨物が滅失し若しくは損傷し、若しくはその滅失若しくは損傷の原因が生じ、又は貨物が延着 したときは、これによって生じた損害を賠償する責任を負います。</span></div>
          <div class="cls_002"><span class="cls_002">ただし、当店が、自己又は使用人その他運送のために使用した者がその貨物の受取、運送、保管及び引渡しについて注意を怠らなかったことを証明したときは、この限りではありません。</span></div>
          <div class="cls_002"><span class="cls_002">( 特殊な管理を要する貨物の運送の責任 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十条 当店は、動物その他特殊な管理を要する貨物の運送について、第十三条第二号の規定に基づき付添人が付された場合には、当該貨物の特殊な管 理について責任を負いません。</span></div>
          <div class="cls_002"><span class="cls_002">( 荷送人の申告等の責任 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十一条 当店は、貨物の内容を容易に知ることができないものについて、送り状の記載又は荷送人の申告により運送受託書、貨物発送通知書等に品 名、品質、重量、容積又は価額を記載したときは、</span></div>
          <div class="cls_002"><span class="cls_002">その記載について責任を 負いません。</span></div>
          <div class="cls_002"><span class="cls_002">( 送り状等の記載の不完全等の責任 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十二条 当店は、送り状若しくは外装表示等の記載又は荷送人の申告が不実又は不備であったために生じた損害については、その責任を負いません。2 前項の場合において、当店が損害を被ったときは、</span></div>
          <div class="cls_002"><span class="cls_002">荷送人はその損害を賠償しなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">( 免責 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十三条 当店は、次の事由による貨物の滅失、損傷、延着その他の損害に ついては、損害賠償の責任を負いません。</span></div>
          <div class="cls_002"><span class="cls_002">一 当該貨物の欠陥、自然の消耗、虫害又は鼠害</span></div>
          <div class="cls_002"><span class="cls_002">二 当該貨物の性質による発火、爆発、むれ、かび、腐敗、変色、さびその他これに類似する事由</span></div>
          <div class="cls_002"><span class="cls_002">三 同盟罷業、同盟怠業、社会的騒擾その他の事変又は強盗</span></div>
          <div class="cls_002"><span class="cls_002">四 不可抗力による火災</span></div>
          <div class="cls_002"><span class="cls_002">五 地震、津波、高潮、大水、暴風雨、地すべり、山崩れ等その他の天災 六 法令又は公権力の発動による運送の差止め、開封、没収、差押え又は第三者への引渡し</span></div>
          <div class="cls_002"><span class="cls_002">七 荷送人又は荷受人の故意又は過失</span></div>
          <div class="cls_002"><span class="cls_002">( 高価品に対する特則 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十四条 高価品については、荷送人が申込みをするに当たり、その種類及び価額を通知しなければ、当店は、その滅失、損傷又は延着について損害賠償の責任を負いません。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定は、次に掲げる場合には、適用しません。</span></div>
          <div class="cls_002"><span class="cls_002">一 運送契約の締結の当時、貨物が高価品であることを当店が知っていたと き。</span></div>
          <div class="cls_002"><span class="cls_002">二 当店の故意又は重大な過失によって高価品の滅失、損傷又は延着が生じ 10 たとき。</span></div>
          <div class="cls_002"><span class="cls_002">( 責任の特別消滅事由 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十五条 当店の貨物の一部滅失又は損傷についての責任は、荷受人が留保しないで貨物を受け取ったときは、消滅します。ただし、貨物に直ちに発見 することのできない損傷又は一部滅失があった場合において、</span></div>
          <div class="cls_002"><span class="cls_002">貨物の引渡し の日から二週間以内に当店に対してその通知を発したときは、この限りでは ありません。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の規定は、貨物の引渡しの当時、当店がその貨物に一部滅失又は損傷 があることを知っていたときは、適用しません。</span></div>
          <div class="cls_002"><span class="cls_002">3 荷送人が第三者から委託を受けた貨物の運送を当店が行う場合において、当該貨物の運送に係る荷受人への貨物の引渡しの日から二週間以内に、</span></div>
          <div class="cls_002"><span class="cls_002">荷送 人が、貨物に直ちに発見することのできない損傷又は一部滅失があった旨の 通知を受けたときは、荷送人に対する当店の責任に係る第一項ただし書の期 間は、</span></div>
          <div class="cls_002"><span class="cls_002">荷送人が当該通知を受けた日から二週間を経過する日まで延長された ものとみなします。</span></div>
          <div class="cls_002"><span class="cls_002">( 損害賠償の額 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十六条 貨物に全部滅失があった場合の損害賠償の額は、その引渡しがされるべき地及び時における貨物の価額によって、これを定めます。</span></div>
          <div class="cls_002"><span class="cls_002">2 貨物に一部滅失又は損傷があった場合の損害賠償の額は、その引渡しがさ れるべき地及び時における、引き渡された貨物の価額と一部滅失又は損傷がなかったときの貨物の価額との差額によってこれを定めます。</span></div>
          <div class="cls_002"><span class="cls_002">3 第三十五条第一項の規定により、貨物の滅失又は損傷のため荷送人又は荷 受人が支払うことを要しない運賃、料金等は、前二項の賠償額よりこれを控除します。</span></div>
          <div class="cls_002"><span class="cls_002">4 第一項及び第二項の場合において、貨物の価額又は損害額について争いがあるときは、公平な第三者の鑑定又は評価によりその額を決定します。</span></div>
          <div class="cls_002"><span class="cls_002">5 貨物が延着した場合の損害賠償の額は、運賃、料金等の総額を限度とします。</span></div>
          <div class="cls_002"><span class="cls_002">第四十七条 当店は、前条の規定にかかわらず、当店の悪意又は重大な過失に よって貨物の滅失、損傷又は延着を生じたときは、それにより生じた一切の 損害を賠償します。</span></div>
          <div class="cls_002"><span class="cls_002">( 除斥期間 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十八条 当店の責任は、貨物の引渡しがされた日 ( 貨物の全部滅失の場合にあっては、その引渡しがされるべき日 ) から一年以内に裁判上の請求がされないときは、消滅します。</span></div>
          <div class="cls_002"><span class="cls_002">2 前項の期間は、貨物の滅失等による損害が発生した後に限り、合意により 延長することができます。</span></div>
          <div class="cls_002"><span class="cls_002">3 荷送人が第三者から委託を受けた貨物の運送を当店が行う場合において、荷送人が第一項の期間内に損害を賠償し又は裁判上の請求をされたときは、荷送人に対する当店の責任に係る同項の期間は、</span></div>
          <div class="cls_002"><span class="cls_002">荷送人が損害を賠償し又は 裁判上の請求をされた日から三月を経過する日まで延長されたものとみなし ます。</span></div>
          <div class="cls_002"><span class="cls_002">( 利用運送の際の責任 )</span></div>
          <div class="cls_002"><span class="cls_002">第四十九条 当店が他の貨物自動車運送事業者の行う運送又は他の運送機関を利用して運送を行う場合においても、運送上の責任は、この運送約款により 当店が負います。</span></div>
          <div class="cls_002"><span class="cls_002">( 賠償に基づく権利取得 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十条 当店が貨物の全部の価額を賠償したときは、当店は、当該貨物に関する一切の権利を取得します。</span></div>
          <div class="cls_002"><span class="cls_002">第九節 連絡運輸</span></div>
          <div class="cls_002"><span class="cls_002">( 通し送り状等 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十一条 連絡運輸に係る貨物の運送を当店が引き受け、かつ、最初の運送を行う場合 ( 以下この節において「連絡運輸の場合」という。) において、当店が送り状を請求したときは、</span></div>
          <div class="cls_002"><span class="cls_002">荷送人は、全運送についての送り状を交付 しなければなりません。</span></div>
          <div class="cls_002"><span class="cls_002">( 運賃、料金等の収受 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十二条 当店は、連絡運輸の場合には、貨物を受け取るときまでに、全運送についての運賃、料金等を収受します。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、前項の規定にかかわらず、全運送についての運賃、料金等を、最後の運送を行った運送事業者が貨物を引き渡すときまでに、荷受人から収受することを認めることがあります。</span></div>
          <div class="cls_002"><span class="cls_002">3 第一項の場合において、運賃、料金等の額が確定しないときは、第三十一条第二項の規定を準用します。</span></div>
          <div class="cls_002"><span class="cls_002">( 中間運送人の権利 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十三条 連絡運輸の場合には、当店より後の運送事業者は、当店に代わってその権利を行使します。</span></div>
          <div class="cls_002"><span class="cls_002">( 責任の原則 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十四条 当店は、連絡運輸の場合には、貨物の滅失、損傷又は延着について、他の運送事業者と連帯して損害賠償の責任を負います。</span></div>
          <div class="cls_002"><span class="cls_002">( 運送約款等の適用 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十五条 連絡運輸の場合には、他の運送事業者の行う運送については、その事業者の運送約款又は運送に関する規定の定めるところによります。</span></div>
          <div class="cls_002"><span class="cls_002">ただ し、貨物の滅失、損傷又は延着による損害が生じた場合であって、かつ、そ の損害を与えた事業者が明らかでない場合の損害賠償の請求については、こ の運送約款の定めるところによります。</span></div>
          <div class="cls_002"><span class="cls_002">( 引渡期間 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十六条 連絡運輸の場合の引渡期間は、各運送事業者ごとに、その運送約款又は運送に関する規定により計算した引渡期間又はそれに相当するものを 合算した期間に、一運送機関ごとに一日を加算したものとします。</span></div>
          <div class="cls_002"><span class="cls_002">( 損害賠償事務の処理 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十七条 連絡運輸の場合には、貨物の滅失、損傷又は延着についての損害賠償は、その請求を受けた運送事業者が損害の程度を調査し、損害賠償の額 を決定してその支払いをします。</span></div>
          <div class="cls_002"><span class="cls_002">( 損害賠償請求権の留保 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十八条 連絡運輸の場合における第四十五条第一項の留保又は通知は、その運送を行った運送事業者のいずれに対しても行うことができます。第三章 附帯業務</span></div>
          <div class="cls_002"><span class="cls_002">( 附帯業務及び附帯業務料 )</span></div>
          <div class="cls_002"><span class="cls_002">第五十九条 当店は、品代金の取立て、荷掛金の立替え、貨物の荷造り、仕分、保管、検収及び検品、横持ち及び縦持ち、棚入れ、ラベル貼り、</span></div>
          <div class="cls_002"><span class="cls_002">はい作 業その他の貨物自動車運送事業に附帯して一定の時間、技能、機器等を必要 とする業務 ( 以下「附帯業務」という。) を引き受けた場合には、</span></div>
          <div class="cls_002"><span class="cls_002">当店が別 に定める料金又は実際に要した費用を収受し、当店の責任においてこれを行 います。</span></div>
          <div class="cls_002"><span class="cls_002">2 附帯業務については、別段の定めがある場合を除き、性質の許す限り、第 二章の規定を準用します。</span></div>
          <div class="cls_002"><span class="cls_002">( 品代金の取立て )</span></div>
          <div class="cls_002"><span class="cls_002">第六十条 品代金の取立ての追付又は変更は、その貨物の発送前に限り、これに応じます。</span></div>
          <div class="cls_002"><span class="cls_002">2 当店は、品代金の取立ての委託を受けた貨物を発送した後、荷送人が、当該品代金の取立ての委託を取り消した場合又は荷送人若しくは荷受人が責任を負う事由により当該品代金の取立てが不能となった場合は、</span></div>
          <div class="cls_002"><span class="cls_002">当該品代金の 取立料の払戻しはしません。</span></div>
          <div class="cls_002"><span class="cls_002">( 付保 )</span></div>
          <div class="cls_002"><span class="cls_002">第六十一条 運送の申込みに際し、当店の申出により荷送人が承諾したときは、当店は、荷送人の費用によって運送保険の締結を引き受けます。</span></div>
          <div class="cls_002"><span class="cls_002">2 保険料率その他運送保険に関する事項は、店頭に掲示します。</span></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalTermsAndConditions",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false
    },
    accepted: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    _visible: {
      get() {
        return this.visible
      },
      set(newValue) {
        return this.$emit('update:visible', newValue)
      }
    },
    _accepted: {
      get() {
        return this.accepted
      },
      set(newValue) {
        return this.$emit('update:accepted', newValue)
      }
    },
  }
}
</script>

<style scoped>
span.cls_002{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_002{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
</style>