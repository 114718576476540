<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" v-for="(field, index) in fields" :key="`field-${field.name}`">
        <template v-if="field.type !== 'space'">
          <div class="label font-weight-bold">
            {{ field.label }}
          </div>
          <template v-if="field.type === 'text' || field.type === 'number' || field.type === 'email'">
            <v-text-field
                :autofocus="index === 0 && readOnly"
                :rules="field.rules"
                v-model="_form[field.name]"
                hide-details="auto"
                class="py-0"
                :placeholder="field.placeholder || ''"
                :type="field.type"
                :readonly="readOnly"
            />
          </template>

          <template v-if="field.type === 'datetime'">
            <v-datetime-picker-customized
                v-model="_form[field.name]"
                no-title
                scrollable
                :text-field-props="{
                  prependIcon: 'mdi-calendar'
                }"
                :date-picker-props="{
                  dayFormat: dayFormat
                }"
                clear-text="クリア"
                manual-input
                date-only
                :read-only="readOnly"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker-customized>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VDatetimePickerCustomized from "@/components/DatetimePicker";
import dayjs from "@/plugins/dayjs";
export default {
  name: "FormDelivery",
  components: {VDatetimePickerCustomized},
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true
    },
    readOnly: Boolean,
    firstItem: Boolean
  },
  computed: {
    _form: {
      get() {
        return this.form
      },
      set(newValue) {
        return this.$emit('update:form', newValue)
      }
    },
  },
  data() {
    return {
      fields: [
        {
          name: 'company_name',
          label: '会社名',
          type: 'text'
        },
        {
          name: 'person',
          label: 'ご担当者名',
          type: 'text'
        },
        {
          name: 'phone',
          label: '電話番号',
          type: 'number'
        },
        {
          name: 'space',
          type: 'space'
        },
        {
          name: 'address',
          label: '住所',
          type: 'text',
          placeholder: '都道府県、市町村、丁目、番地',
          rules: this.firstItem ? [(value) => !!value || '住所は必須です' ] : []
        },
        {
          name: 'address_detail',
          label: '住所',
          type: 'text',
          placeholder: 'ビル名、部屋番号'
        },
        {
          name: 'datetime',
          label: '配達日時',
          type: 'datetime'
        },
      ]
    }
  },
  methods: {
    dayFormat(e) {
      return dayjs(e).format('D')
    }
  }
}
</script>

<style scoped>

</style>