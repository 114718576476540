<template>
  <v-dialog v-model="_visible" max-width="700px" persistent>
    <v-card>
      <v-card-title class="text-h5">ご依頼者（お支払い方）情報を入力
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('click:close-dialog')">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <FormOwner :form="_form" :address-type.sync="ownerAddressType" />
        </v-form>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="auto" offset-sm="5" class="text-center text-sm-left">
            <v-btn
                color="green darken-1"
                class="white--text"
                min-width="130"
                @click="$emit('click:return')"
            >
              戻る
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
                color="green darken-1"
                class="white--text"
                @click="submit()"
            >
              入力情報の確認
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormOwner from "@/components/FormOwner";
export default {
  name: "ModalOwner",
  components: {FormOwner},
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false
    },
    form: {
      type: Object,
      default: () => {},
      required: true
    },
    addressType: String
  },
  computed: {
    _form: {
      get() {
        return this.form
      },
      set(newValue) {
        return this.$emit('update:form', newValue)
      }
    },
    _visible: {
      get() {
        return this.visible
      },
      set(newValue) {
        return this.$emit('update:visible', newValue)
      }
    },
    ownerAddressType: {
      get() {
        return this.addressType
      },
      set(newValue) {
        return this.$emit('update:address-type', newValue)
      }
    },

    buttonText() {
      return this.form.id ? 'Edit' : '追加'
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('click:submit')
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style scoped>

</style>