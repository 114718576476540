<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-radio-group :readonly="readOnly" row v-model="ownerAddressType">
          <v-radio label="集荷先と同じ" value="pickup"></v-radio>
          <v-radio label="配達先と同じ" value="delivery"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" v-for="field in fields" :key="`field-${field.name}`">
        <template v-if="field.type !== 'space'">
          <div class="label font-weight-bold">
            {{ field.label }}
          </div>
          <template v-if="field.type === 'text' || field.type === 'number' || field.type === 'phone' || field.type === 'email'">
            <v-text-field
                required
                v-model="_form[field.name]"
                hide-details="auto"
                class="py-0"
                :placeholder="field.placeholder || ''"
                :readonly="readOnly"
                :rules="field.rules"
            />
          </template>

          <template v-if="field.type === 'datetime'">
            <v-datetime-picker-customized
                v-model="_form[field.name]"
                no-title
                scrollable
                :text-field-props="{
                  prependIcon: 'mdi-calendar'
                }"
                :date-picker-props="{
                  dayFormat: dayFormat
                }"
                clear-text="クリア"
                manual-input
                date-only
                :read-only="readOnly"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker-customized>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VDatetimePickerCustomized from "@/components/DatetimePicker";
import dayjs from "@/plugins/dayjs";
export default {
  name: "FormOwner",
  components: {VDatetimePickerCustomized},
  props: {
    form: {
      type: Object,
      default: () => {},
      required: true
    },
    readOnly: Boolean,
    addressType: String
  },
  computed: {
    _form: {
      get() {
        return this.form
      },
      set(newValue) {
        return this.$emit('update:form', newValue)
      }
    },
    ownerAddressType: {
      get() {
        return this.addressType
      },
      set(newValue) {
        return this.$emit('update:address-type', newValue)
      }
    }
  },
  data() {
    return {
      fields: [
        {
          name: 'company_name',
          label: '会社名',
          type: 'text'
        },
        {
          name: 'person',
          label: 'ご担当者名',
          type: 'text'
        },
        {
          name: 'phone',
          label: '電話番号',
          type: 'phone'
        },
        {
          name: 'email',
          label: 'メールアドレス',
          type: 'email',
          rules: [
            (value) => !!value || 'メールアドレスは必須項目です',
            (value) => /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(value) || 'メールアドレスが間違っております。'
          ]
        },
        {
          name: 'address',
          label: '住所',
          type: 'text',
          placeholder: '都道府県、市町村、丁目、番地'
        },
        {
          name: 'address_detail',
          label: '住所',
          type: 'text',
          placeholder: 'ビル名、部屋番号'
        },
        {
          name: 'content_of_luggage',
          label: 'お荷物の内容',
          type: 'text'
        }
      ]
    }
  },
  methods: {
    dayFormat(e) {
      return dayjs(e).format('D')
    }
  }
}
</script>

<style scoped>

</style>