<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        v-on="!manualInput ? on : ''"
        :readonly="!manualInput || readOnly"
        :clearable="manualInput && !readOnly"
        v-mask="'####-##-## ##:##'"
        @input="manualInput ? checkManualInputFormat($event) : ''"
        @blur="manualInput ? updateManualInput($event) : ''"
        hide-details="auto"
        :error="!isInputValid"
        :error-messages="errorMessages"
        class="py-0"
      >
        <template v-slot:prepend>
          <v-icon v-on="manualInput && !readOnly ? on : ''">{{ textFieldProps.prependIcon }}</v-icon>
        </template>
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>event</v-icon>
            </slot>
          </v-tab>
          <v-tab v-if="!dateOnly" key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>access_time</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="!dateOnly ? showTimePicker : ''"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item v-if="!dateOnly" key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
            clearText
          }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{
            okText
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "@/plugins/dayjs";
const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";
export default {
  name: "v-datetime-picker-customized",
  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    manualInput: {
      type: Boolean,
      default: false
    },
    dateOnly: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      isInputValid: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? dayjs(this.selectedDatetime).format(this.dateTimeFormat)
        : "";
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return dayjs(datetimeString).format(this.defaultDateTimeFormat);
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    },
    errorMessages() {
      return this.isInputValid ? '' : 'Input is not valid'
    }
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }
      let initDateTime = dayjs(this.datetime, this.dateTimeFormat);
      this.date = initDateTime.format(DEFAULT_DATE_FORMAT);
      this.time = initDateTime.format(DEFAULT_TIME_FORMAT);
      this.checkManualInputFormat(this.datetime)
    },
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
    checkManualInputFormat(e) {
      this.isInputValid = dayjs(e).isValid()
    },
    updateManualInput(e) {
      if(!this.isInputValid) return
      let value;
      if(typeof e === 'object')
        value = e.target.value;
      else
        value = e
      let initDateTime = dayjs(value, this.dateTimeFormat);
      this.date = initDateTime.format(DEFAULT_DATE_FORMAT);
      this.time = initDateTime.format(DEFAULT_TIME_FORMAT);
      this.$emit("input", this.selectedDatetime);
    }
  },
  watch: {
    datetime: function() {
      this.init();
    },
  },
};
</script>
